










































import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { DerivativeInfoType } from '../DerivativeInfos/types'
import filters from '@/filters'

type Record = {
  exchange: string
  bidSize: number | string
  bidPrice: number | string
  bidRate: number | string
  askSize: number | string
  askPrice: number | string
  askRate: number | string
}

export default Vue.extend({
  props: {
    maxHeightExchangeRecords: {
      type: Number,
      required: true
    }
  },

  data(): {
    otherExchangesDerivativeInfo: DerivativeInfoType[]
    currentDerivativeInfo: DerivativeInfoType | undefined
    loading: boolean
  } {
    return {
      otherExchangesDerivativeInfo: [],
      currentDerivativeInfo: undefined,
      loading: false
    }
  },

  computed: {
    ...mapState('exchange', ['exchange', 'pair', 'market']),

    ...mapGetters('exchange', ['priceDecimal', 'sizeDecimal']),

    records(): Record[] {
      return this.otherExchangesDerivativeInfo.map((item) => ({
        exchange: item.exchange,
        bidSize: filters.toLongFloatNumberWithCommas(item.bids[0][1], this.sizeDecimal),
        bidPrice: filters.toLongFloatNumberWithCommas(item.bids[0][0], this.priceDecimal),
        bidRate:
          this.currentDerivativeInfo?.bids && this.currentDerivativeInfo.bids.length > 0
            ? this.comparisonPercentage(item.bids[0][0], this.currentDerivativeInfo.bids[0][0]) + '%'
            : '0%',
        askSize: filters.toLongFloatNumberWithCommas(item.asks[0][1], this.sizeDecimal),
        askPrice: filters.toLongFloatNumberWithCommas(item.asks[0][0], this.priceDecimal),
        askRate:
          this.currentDerivativeInfo?.asks && this.currentDerivativeInfo.asks.length > 0
            ? this.comparisonPercentage(item.asks[0][0], this.currentDerivativeInfo.asks[0][0]) + '%'
            : '0%'
      }))
    }
  },

  methods: {
    connectSocket() {
      this.$socket.open()
      this.$socket.on('REALTIME_ORDERBOOK', (data: DerivativeInfoType) => {
        if (data.symbol === this.pair?.symbol) {
          // get current data order book
          if (data.exchange === this.exchange) {
            this.currentDerivativeInfo = data
          } else {
            const index = this.otherExchangesDerivativeInfo.findIndex(
              (item: DerivativeInfoType) => item.exchange === data.exchange
            )
            index >= 0
              ? this.otherExchangesDerivativeInfo.splice(index, 1, data)
              : this.otherExchangesDerivativeInfo.push(data)
          }
        }
      })
    },

    comparisonPercentage(currentPrice: number, otherPrice: number): string {
      return String(filters.toFloatNumberWithCommas((currentPrice / otherPrice - 1) * 100, 3))
    }
  },

  mounted() {
    this.connectSocket()
  },

  watch: {
    exchange() {
      this.otherExchangesDerivativeInfo = []
      this.currentDerivativeInfo = undefined
      this.connectSocket()
    },

    pair: {
      handler() {
        this.otherExchangesDerivativeInfo = []
        this.currentDerivativeInfo = undefined
        this.connectSocket()
      },
      deep: true
    }
  },

  beforeDestroy() {
    this.$socket.disconnect()
  }
})
